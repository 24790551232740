<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Reports</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "satellite_reports.svg",
          title: "Satellite Reports",
          url: "my-reports/satellite-reports",
        },
        {
          img: "weather_reports.svg",
          title: "Weather Reports",
          url: "my-reports/weather-reports",
        },
        {
          img: "crop_reports.svg",
          title: "Crop Reports",
        },
        {
          img: "livestock_reports.svg",
          title: "Livestock Reports",
        },
        {
          img: "market_reports.svg",
          title: "Market Reports",
        },
        {
          img: "financial_reports.svg",
          title: "Financial Reports",
        },
        {
          img: "genetic_reports.svg",
          title: "Genetic Reports",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/my_reports/" + img;
    },
  },
};
</script>
